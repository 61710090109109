import { ProposalCard } from "@/app/types/proposals";
import { getProposalState } from "@/app/helpers/proposalLogHelpers";

const fetchFromApi = async (url: string): Promise<any> => {
  const response = await fetch(url);
  if (!response.ok) {
    throw new Error(`Failed to fetch data from ${url}`);
  }
  return response.json();
};

const isValidDate = (dateString: string) => {
  const date = new Date(dateString);
  return !isNaN(date.getTime());
};

export const formatProposalDetails = (proposals: any) => {
  return proposals.map((proposal: any) => ({
    proposal_id: proposal.proposal_id,
    title: proposal.title,
    proposer: proposal.proposer,
    snapshot: proposal.snapshot,
    state: getProposalState(proposal.state),
    created_at: isValidDate(proposal.created_at)
      ? new Date(proposal.created_at)
      : null,
    description: proposal.description || "No Description Available",
    targets: (proposal.targets as `0x${string}`[]) || [],
    calldatas: proposal.calldatas.map((calldata: string) => ({
      method: "",
      types: [],
      values: [],
      encodedData: calldata,
    })),
    vals: proposal.vals || [],

    votes_for: proposal.votes_for || "0",
    votes_against: proposal.votes_against || "0",
    votes_abstain: proposal.votes_abstain || "0",
    vote_end: isValidDate(proposal.vote_end)
      ? new Date(proposal.vote_end).toISOString()
      : null,
    quorum: (BigInt(proposal.quorum) / BigInt(10 ** 18)).toLocaleString(),
    voters: proposal.voters || 0,
  }));
};

export const fetchProposals = async (): Promise<{
  proposals: ProposalCard[];
  totalProposals: number;
  passed: number;
  failed: number;
}> => {
  try {
    const data = await fetchFromApi(process.env.NEXT_PUBLIC_PROPOSALS_API!);
    const proposals = formatProposalDetails(data);

    const totalProposals = data.length;
    const passed = data.filter((proposal: any) => proposal.state === 4).length;
    const failed = data.filter((proposal: any) => proposal.state === 3).length;

    return {
      proposals,
      totalProposals,
      passed,
      failed,
    };
  } catch (error) {
    console.error("Error fetching proposals:", error);
    throw error;
  }
};
