"use client";

import { useEffect, useState, useRef } from "react";
import { useAccount } from "wagmi";
import { useFetchProposals } from "@/app/hooks/useFetchProposals";
import { useTokenContext } from "@/app/context/TokensContext";

type WebSocketMessage = {
  data: string;
  delegates?: boolean;
  proposals?: boolean;
  votes?: boolean;
};

export const useWebSocket = (): WebSocketMessage[] => {
  const [messages, setMessages] = useState<WebSocketMessage[]>([]);
  const [socket, setSocket] = useState<WebSocket | null>(null);
  const { address } = useAccount();
  const addressRef = useRef(address);
  const { mutation } = useFetchProposals();
  const { reFetchDelegates } = useTokenContext();

  useEffect(() => {
    addressRef.current = address;
  }, [address]);

  useEffect(() => {
    const connectWebSocket = () => {
      const ws = new WebSocket(process.env.NEXT_PUBLIC_WSS_CONNECTION!);

      // ws.onopen = () => {
      //   console.log("WebSocket connection opened");
      // };

      ws.onmessage = async (event: MessageEvent) => {
        // console.log("event in useWebSocket", event.data);
        const eventData = event.data;
        const { delegates, proposals, votes } = JSON.parse(eventData);
        // console.log("delegates, proposals, votes", delegates, proposals, votes);
        // console.log(
        //   "!!delegates, !!proposals, !!votes",
        //   !!delegates,
        //   !!proposals,
        //   !!votes,
        // );

        if (!!proposals || !!votes) {
          // console.log("Fetching proposals");
          await mutation.mutate();
        }

        if (!!delegates) {
          // console.log("Fetching delegates");
          await reFetchDelegates();
        }
      };

      ws.onclose = () => {
        // console.log("WebSocket connection closed");

        setTimeout(() => {
          connectWebSocket();
        }, 5000);
      };

      ws.onerror = (error: Event) => {
        console.error("WebSocket Error:", error);
        ws.close();
      };

      setSocket(ws);
    };

    connectWebSocket();

    return () => {
      if (socket) {
        socket.close();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return messages;
};
