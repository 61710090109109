import { ethers, formatUnits } from "ethers";
import { governorABI } from "@/app/abi";
import { abbreviateValue } from "@/app/helpers/abbreviateValue";
import {
  VotingPeriodData,
  VotingDelayData,
  CirculatingSupplyData,
} from "@/app/types/governor";

const governorContractAddress = process.env
  .NEXT_PUBLIC_GOVERNOR_ADDRESS as `0x${string}`;

const provider = new ethers.JsonRpcProvider(
  process.env.NEXT_PUBLIC_PROVIDER_URL,
);

async function getGovernorContract() {
  return new ethers.Contract(governorContractAddress, governorABI, provider);
}

export async function canVote(
  proposalId: number,
  account: string,
): Promise<{ canVote: boolean; delegatedTokens: string }> {
  try {
    const governorContract = await getGovernorContract();

    const proposalSnapshot =
      await governorContract.proposalSnapshot(proposalId);

    const votingPower = await governorContract.getVotes(
      account,
      proposalSnapshot,
    );

    const votingPowerBigInt = BigInt(votingPower.toString());
    const canVote = votingPowerBigInt > BigInt(0);

    const delegatedTokens = formatUnits(votingPower, 18);

    return { canVote, delegatedTokens };
  } catch (error) {
    console.error("Error in canVote function:", error);
    throw error;
  }
}

export async function fetchProposalThreshold(): Promise<string> {
  try {
    const contract = new ethers.Contract(
      governorContractAddress,
      governorABI,
      provider,
    );
    const data = await contract.proposalThreshold();

    // Check if the data is empty or invalid
    if (data === "0x" || !data) {
      throw new Error(
        "Invalid response from proposalThreshold method: " + data,
      );
    }

    let formattedThreshold = formatUnits(data, 18);

    // Remove trailing ".0" if it exists
    formattedThreshold = formattedThreshold.replace(/\.0$/, "");

    // Add commas for thousands separators
    const parts = formattedThreshold.split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    formattedThreshold = parts.join(".");

    return formattedThreshold;
  } catch (error) {
    console.error("Error fetching proposalThreshold:", error);
    throw error;
  }
}

export async function fetchQuorumNumerator(): Promise<string> {
  const contract = await getGovernorContract();
  const data = await contract.quorumNumerator();
  return data.toString();
}

export async function fetchQuorumDenominator(): Promise<string> {
  const contract = await getGovernorContract();
  const data = await contract.quorumDenominator();
  return data.toString();
}

export async function fetchVotingPeriod(): Promise<VotingPeriodData> {
  const contract = await getGovernorContract();
  const data = await contract.votingPeriod();
  const blocksPerDay = 7200;
  const votingPeriodInBlocks = parseInt(data.toString());
  const formattedVotingPeriod = formatVotingPeriod(
    votingPeriodInBlocks,
    blocksPerDay,
  );
  return { votingPeriodInBlocks, formattedVotingPeriod };
}

const averageBlockTimeInSeconds = 15;

export async function fetchVotingDelay(): Promise<VotingDelayData> {
  const contract = await getGovernorContract();
  const data = await contract.votingDelay();

  const votingDelayInBlocks = parseInt(data.toString(), 10);

  const votingDelayInSeconds = votingDelayInBlocks * averageBlockTimeInSeconds;

  const formattedVotingDelay = formatDelay(votingDelayInSeconds);

  return { formattedVotingDelay };
}

export async function fetchCirculatingSupply(): Promise<CirculatingSupplyData> {
  const contract = await getGovernorContract();
  const data = await contract.circulatingSupply();

  const formattedSupply = formatUnits(data, 18);

  const supplyNumber = parseFloat(formattedSupply);

  const circulatingSupply = Math.round(supplyNumber).toLocaleString();

  const abbreviatedSupply = abbreviateValue(Math.round(supplyNumber));

  return { circulatingSupply, abbreviatedSupply };
}

export async function fetchTokenAddress(): Promise<string> {
  const contract = await getGovernorContract();
  const data = await contract.token();
  return data;
}

export async function fetchTimelockAddress(): Promise<string> {
  const contract = await getGovernorContract();
  const data = await contract.timelockControllerAddress();
  return data;
}

export async function hasVoted(
  proposalId: number,
  account: string,
): Promise<boolean> {
  const contract = await getGovernorContract();
  const hasVoted = await contract.hasVoted(proposalId, account);
  return hasVoted;
}

function formatVotingPeriod(
  blocks: number | null,
  blocksPerDay: number,
): string | null {
  if (blocks === null) return null;

  const days = blocks / blocksPerDay;
  const hours = days * 24;

  const daysPerMonth = 30;
  const daysPerYear = 365;
  const daysPerWeek = 7;

  if (days >= daysPerYear) {
    return `${(days / daysPerYear).toFixed(2).replace(/\.00$/, "")} years`;
  } else if (days >= daysPerMonth) {
    return `${(days / daysPerMonth).toFixed(2).replace(/\.00$/, "")} months`;
  } else if (days >= daysPerWeek) {
    return `${(days / daysPerWeek).toFixed(2).replace(/\.00$/, "")} weeks`;
  } else if (days >= 1) {
    return `${days.toFixed(2).replace(/\.00$/, "")} days`;
  } else if (hours >= 1) {
    return `${hours.toFixed(2).replace(/\.00$/, "")} hours`;
  } else {
    return `${(hours * 60).toFixed(2).replace(/\.00$/, "")} min`;
  }
}

function formatDelay(seconds: number): string | null {
  if (seconds === null) return null;

  const secondsPerMinute = 60;
  const secondsPerHour = secondsPerMinute * 60;
  const secondsPerDay = secondsPerHour * 24;
  const secondsPerWeek = secondsPerDay * 7;
  const secondsPerMonth = secondsPerDay * 30;
  const secondsPerYear = secondsPerDay * 365;

  const calculations = [
    { unit: "years", value: secondsPerYear },
    { unit: "months", value: secondsPerMonth },
    { unit: "weeks", value: secondsPerWeek },
    { unit: "days", value: secondsPerDay },
    { unit: "hours", value: secondsPerHour },
    { unit: "min", value: secondsPerMinute },
    { unit: "seconds", value: 1 },
  ];

  for (let { unit, value } of calculations) {
    if (seconds >= value) {
      const number = seconds / value;
      const isInteger = number % 1 === 0;
      return formatNumber(number) + ` ${unit}`;
    }
  }

  return null;

  function formatNumber(value: number) {
    if (value % 1 === 0) {
      return value.toString();
    } else {
      return value.toFixed(2).replace(/\.00$/, "");
    }
  }
}
