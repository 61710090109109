import { ReactElement } from "react";
import { ethers } from "ethers";
import { EventTypes } from "@/app/types/events";
import { ProposalMapping } from "../types/proposals";

export const sanitizeTitle = (title: string) => {
  return title.replace(/[!@#^&*+{}\[\]?>]/g, "");
};

export const truncateAddress64 = (address: string) => {
  if (!address) return "";
  return `${address.slice(0, 6)}...${address.slice(-4)}`;
};

export const getProposalState = (
  state: number,
): {
  text: string;
  bgColor: string;
  textColor: string;
  icon?: ReactElement;
} => {
  switch (state) {
    case 0:
      return {
        text: "Pending",
        bgColor: "bg-pending",
        textColor: "text-neutral-950",
      };
    case 1:
      return {
        text: "Active",
        bgColor: "bg-active",
        textColor: "text-neutral-50",
      };
    case 2:
      return {
        text: "Canceled",
        bgColor: "bg-gray-500",
        textColor: "text-neutral-50",
      };
    case 3:
      return {
        text: "Defeated",
        bgColor: "bg-defeated",
        textColor: "text-neutral-950",
      };
    case 4:
      return {
        text: "Succeeded",
        bgColor: "bg-accent",
        textColor: "text-neutral-950",
      };
    case 5:
      return {
        text: "Queued",
        bgColor: "bg-[#a88aea]",
        textColor: "text-neutral-50",
      };
    case 6:
      return {
        text: "Expired",
        bgColor: "bg-gray-500",
        textColor: "text-neutral-50",
      };
    case 7:
      return {
        text: "Executed",
        bgColor: "bg-purple",
        textColor: "text-neutral-50",
      };
    default:
      return {
        text: "Unknown",
        bgColor: "bg-gray-500",
        textColor: "text-neutral-50",
      };
  }
};

export const proposalMapping: ProposalMapping = {
  "87408652050374642399550471985234797943112931039812841893927618845023028168518":
    {
      title: "Reinstate Staking",
      description:
        "Reinstate soft-staking of Hooligans using the same parameters and allocated $KARRAT as before.",
    },
  "86688801169331961903901152795884970744101191989497597233377585787033091416667":
    {
      title:
        "Token Burn Proposal to Combat Inflation and Boost Investor Appeal",
      description:
        "To combat the downward price action caused by token dilution by offsetting the supply that is being added over time. This proposal aims to make $karrat more appealing to new investors by increasing the circulating supply percentage without additional dilution. Proposal: We propose to burn 100,000,000 $karrat tokens from the DAO's treasury, which currently holds 30% of the total supply. This action will effectively reduce the total max supply of $karrat by 10%. Rationale: 1. Combating Dilution: As new tokens are added to the supply over time, the value of existing tokens tends to decrease due to dilution. By burning a significant portion of the treasury's holdings, we can counteract this effect and help stabilize the token's value. 2. Increasing Circulating Supply: Currently, only 10-12% of the total $karrat supply is in circulation. Burning 10% of the total supply will increase the circulating supply to 20-22%, making $karrat more attractive to investors by reducing the impact of dilution on their holdings. 3. Investor Appeal: A higher circulating supply percentage can make $karrat more appealing to new investors, as it demonstrates proactive measures to maintain and potentially increase the token's value. This can lead to increased investor confidence and market interest in $karrat. Conclusion: By implementing this burn of 100,000,000 $karrat tokens, we aim to enhance the overall value proposition of $karrat, making it a more stable and attractive investment option. This strategic move is expected to benefit both current and future investors, promoting the long-term success and sustainability of the $karrat ecosystem.",
    },
  "113340420129177205214962554583934694393201230831626211776955007357940577773261":
    {
      title: "Voting Power Change - To Hooligan Holders",
      description:
        "The project started with hooligans, and will always be for hooligans. With that, the voting power should be placed with the holders of hooligans. 1 hooligan = 1 vote. Simple theory, simple proposal.",
    },
};
